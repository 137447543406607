module.exports={
	"video_crop_px": "0",
	"transform_type": "3d",
	"transitions": {
		"ease": "ease-in-out",
		"duration_ms": "350",
		"delay_interval_ms": "34",
		"min_delay_ms": "34"
	},
	"breakpoints": {
		"landscape": "'(orientation: landscape)'",
		"portrait": "'(orientation: portrait)'",
		"xsmall-stretch": "'not screen and (min-width: 460px), not screen and (min-height: 420px)'",
		"small-stretch": "'not screen and (min-width: 704px), not screen and (min-height: 420px)'",
		"default":"'only screen and (min-width: 704px) and (min-height: 420px)'",
		"medium-wide": "'only screen and (min-width: 1024px) and (max-width: 1223px) and (min-height: 420px)'",
		"medium-wide-stretch": "'only screen and (min-width: 1224px) and (min-height: 420px)'"
	},
	"default_colors": {
		"color": "hsl(47, 5%, 15%)",
		"background-color": "hsl(47, 5%, 95%)",
		"link-color": "hsl(10, 80%, 50%)"
	},
	"temp": {
		"collapse_offset": "360"
	},
	"_ignore": {
		"transitions": {
			"ease": "cubic-bezier(0.42, 0.0, 0.58, 1.0)",
			"duration_ms": "400",
			"delay_interval_ms": "134",
			"min_delay_ms": "34"
		},
		"default_colors": {
			"--link-color": "hsl(10, 80%, 50%)",
			"--alt-background-color": "unset"
		},
		"units": {
			"hu_px": "20",
			"vu_px": "12"
		},
		"breakpoints": {
			"medium-wide-stretch": "'only screen and (min-width: 1024px) and (min-height: 420px)'",
			"large-wide": "'only screen and (min-width: 1824px) and (min-height: 1024px)'",
			"mobile": "'not screen and (min-width: 704px), not screen and (min-height: 540px)'",
			"unsupported": "'not screen and (min-width: 704px)'",
			"unquoted": "only screen and (min-width: 1824px)",
			"unquoted_neg": "not screen and (min-width: 704px)",
			"quoted_combined": "'not screen and (min-width: 704px), not screen and (min-height: 540px)'",
			"array": [
				"only screen and (min-width: 704px)",
				"not screen and (min-width: 704px)",
				"not screen and (min-height: 540px)"
			]
		}
	}
}
